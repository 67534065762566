@import "./assets/scss/app.scss";

form .row {
  margin-bottom: 1rem;
}

.folder-box {
  background-color: #f6f7fb;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  display: inline-block;
  padding: 15px;
  cursor: pointer;
}

.table tbody tr td {
  vertical-align: middle;
}

.logos-europa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}
